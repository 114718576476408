.input-container {
    width: 100%;
    margin-top: 6px;
    .input-wrapper {
        position: relative;
        @apply border-2 border-slate-200;
        border-radius: 4px;
        input {
            all: unset;
            width: 100%;
            margin: 0;
            padding: 0;
            font-size: 16px;
            padding-left: 12px;
            height: 32px;
            &:focus {
                border-color: black;
            }
        }
        
        .list-box {
            width: 100%;
            max-height: 213px;
            bottom: 38px;
            display: flex;
            flex-direction: column;
            position: absolute;
            overflow: auto;
            .item {
                // all: unset;
                @apply border-2 border-gray-200;
                cursor: pointer;
                padding: 6px;
                height: 36px;
                &:first-child {
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;
                }
                &:not(:first-child) {
                    border-top: 1px;
                }
                &:last-child {
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 3px;
                }
            }
        }
    }
}